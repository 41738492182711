import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
export default defineComponent({
  name: 'Sliders',
  props: ['sliders', 'desktop', 'defaultURL', 'unit'],
  setup: function setup(props) {
    var updateUrl = function updateUrl(slider) {
      slider.image = props.defaultURL;
    };

    var buttonState = reactive({
      shouldNextBeValid: false,
      shouldPreviousBeValid: false
    });
    onMounted(function () {
      props && restyle();
    });
    var sliderStyle = ref({
      transform: 'translate3d(0, 0, 0)'
    });
    var current = ref(0);
    var index = computed({
      get: function get() {
        return current.value;
      },
      set: function set(val) {
        current.value = val;
      }
    });

    var reset = function reset() {
      current.value = 0;
    };

    var next = function next() {
      current.value++;
      restyle();
    };

    var previous = function previous() {
      if (current.value > 0) {
        current.value--;
        restyle();
      }
    };

    var restyle = function restyle() {
      var _props$sliders;

      var total = props === null || props === void 0 ? void 0 : (_props$sliders = props.sliders) === null || _props$sliders === void 0 ? void 0 : _props$sliders.length;
      var unit = props === null || props === void 0 ? void 0 : props.unit;
      var moveCount = 1;
      var oneMoveDistance = -current.value * 100 / unit;
      sliderStyle.value.transform = "translate3d(".concat(oneMoveDistance * moveCount, "%, 0, 0)");
      buttonState.shouldPreviousBeValid = true;
      buttonState.shouldNextBeValid = true;

      if (total - unit === current.value * 1) {
        buttonState.shouldNextBeValid = false;
      }

      if (current.value === 0) {
        buttonState.shouldPreviousBeValid = false;
      }
    };

    return {
      updateUrl: updateUrl,
      buttonState: buttonState,
      sliderStyle: sliderStyle,
      index: index,
      reset: reset,
      next: next,
      previous: previous
    };
  }
});