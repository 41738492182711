import { defineComponent, onMounted } from 'vue';
import SearchSection from '@/components/home/SearchSection.vue';
import Header from '@/components/Header.vue';
import Banner from '@/components/home/Banner.vue';
import HitoSection from '@/components/home/HitoSection.vue';
import HotSpotSection from '@/components/home/HotSpotSection.vue';
import InfoSection from '@/components/home/InfoSection.vue';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import { setKeywordMeta } from '@/utils/setKeywordMeta';
export default defineComponent({
  name: 'Home',
  components: {
    SearchSection: SearchSection,
    HeaderComponent: Header,
    Banner: Banner,
    HitoSection: HitoSection,
    HotSpotSection: HotSpotSection,
    InfoSection: InfoSection,
    FooterComponent: Footer
  },
  setup: function setup() {
    var store = useStore();
    onMounted(function () {
      store.dispatch('form/setKeyword', undefined);
      store.dispatch('form/setSearchCondition', {
        field: 'business_type',
        value: 2
      });
      store.dispatch('options/setGuestNumber', 2);
      setKeywordMeta('2'); // set quarantine hotel's business type meta keyword
    });
  }
});