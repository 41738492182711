import "core-js/modules/es.string.link.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-76225ae0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
var _hoisted_2 = {
  key: 1,
  class: "pc-slider"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_swipe_item = _resolveComponent("van-swipe-item");

  var _component_van_swipe = _resolveComponent("van-swipe");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_slider = _resolveComponent("slider");

  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.isMobile ? _withDirectives((_openBlock(), _createBlock(_component_van_swipe, {
    key: 0,
    class: "mobile-slider",
    "indicator-color": "white",
    autoplay: 3000
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannerData, function (data, index) {
        return _openBlock(), _createBlock(_component_van_swipe_item, {
          key: index
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", {
              class: "img",
              style: _normalizeStyle({
                'background-image': 'url(' + (data === null || data === void 0 ? void 0 : data.image) + ')'
              }),
              onClick: function onClick($event) {
                return _ctx.openNewWindow(data.link);
              }
            }, null, 12, _hoisted_1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }, 512)), [[_vShow, _ctx.bannerData.length > 0]]) : _createCommentVNode("", true), !_ctx.isMobile && _ctx.bannerData.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_slider, {
    sliders: _ctx.bannerData,
    unit: 2
  }, {
    previous: _withCtx(function (props) {
      return [_createElementVNode("button", {
        class: "slider-btn left",
        type: "button",
        onClick: function onClick($event) {
          return props.previous();
        }
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "icon_48_leftarrow_big_enable"
      })], 8, _hoisted_3)];
    }),
    container: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannerData, function (data, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "img card",
          style: _normalizeStyle({
            'background-image': 'url(' + (data === null || data === void 0 ? void 0 : data.image) + ')'
          }),
          onClick: function onClick($event) {
            return _ctx.openNewWindow(data.link);
          }
        }, null, 12, _hoisted_4);
      }), 128))];
    }),
    next: _withCtx(function (props) {
      return [_createElementVNode("button", {
        class: "slider-btn right",
        type: "button",
        onClick: function onClick($event) {
          return props.next();
        }
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "icon_48_rightarrow_big_enable"
      })], 8, _hoisted_5)];
    }),
    _: 1
  }, 8, ["sliders"])])) : _createCommentVNode("", true)], 64);
}