import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-59531db8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "hito"
};
var _hoisted_2 = {
  class: "list"
};
var _hoisted_3 = {
  class: "center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_hito_hotel_card = _resolveComponent("hito-hotel-card");

  var _component_van_tab = _resolveComponent("van-tab");

  var _component_van_tabs = _resolveComponent("van-tabs");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h3", {
    class: _normalizeClass(_defineProperty({
      title: true
    }, _ctx.className, true))
  }, _toDisplayString(_ctx.eventTitle ? _ctx.eventTitle : _ctx.t('homepage.popular_quarantine_hotels')), 3), _createVNode(_component_van_tabs, {
    lineHeight: "0",
    active: _ctx.activeName,
    "onUpdate:active": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.activeName = $event;
    })
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, function (tag, index) {
        return _openBlock(), _createBlock(_component_van_tab, {
          title: tag.city_name,
          key: index,
          titleClass: "my-tab"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelsData[tag.city_id], function (data, index) {
              return _openBlock(), _createBlock(_component_hito_hotel_card, {
                hotel: data,
                key: index
              }, null, 8, ["hotel"]);
            }), 128))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
              class: "hotel-btn",
              onClick: _cache[0] || (_cache[0] = function ($event) {
                return _ctx.handleGoHotels();
              })
            }, _toDisplayString(_ctx.t('homepage.see_more')), 1)])];
          }),
          _: 2
        }, 1032, ["title"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["active"])]);
}