import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_mobile_search = _resolveComponent("mobile-search");

  var _component_home_pc_search = _resolveComponent("home-pc-search");

  return _ctx.isMobile ? (_openBlock(), _createBlock(_component_mobile_search, {
    key: 0,
    className: "home-mobile-search",
    initExpandStatus: true,
    home: true
  })) : (_openBlock(), _createBlock(_component_home_pc_search, {
    key: 1
  }));
}