import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import { computed, defineComponent, inject } from 'vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup(props) {
    var t = inject('t');

    var _useSearchHotels = useSearchHotels(),
        handleGoHotel = _useSearchHotels.handleGoHotel;

    var features = computed(function () {
      var _props$hotel;

      return (_props$hotel = props.hotel) !== null && _props$hotel !== void 0 && _props$hotel.features ? Object.values(props.hotel.features).filter(function (val) {
        return val;
      }) : [];
    });
    return {
      t: t,
      handleGoHotel: handleGoHotel,
      features: features
    };
  }
});