import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import HitoHotelCard from './HitoHotelCard.vue';
import { useStore } from 'vuex';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Tab.name, _Tab), _defineProperty(_components, _Tabs.name, _Tabs), _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, "HitoHotelCard", HitoHotelCard), _components),
  props: {
    eventTitle: String,
    className: String
  },
  setup: function setup() {
    var store = useStore();
    var t = inject('t');
    var activeName = ref(0);
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var hotelsData = computed(function () {
      return store.state.hotels.hitoHotels;
    });
    var locale = inject('locale');
    var tags = computed(function () {
      var names = [{
        name: 'homepage.all_county_city',
        cityId: 0
      }, {
        name: 'homepage.taipei',
        cityId: 1
      }, {
        name: 'homepage.new_taipei',
        cityId: 3
      }, {
        name: 'homepage.taoyuan',
        cityId: 5
      }, {
        name: 'homepage.taichung',
        cityId: 9
      }, {
        name: 'homepage.tainan',
        cityId: 15
      }, {
        name: 'homepage.kaohsiung',
        cityId: 16
      }];
      return names.map(function (city) {
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          city_name: t(city.name),
          city_id: city.cityId
        };
      });
    });
    onMounted(function () {
      if (Object.keys(hotelsData.value).length === 0) {
        store.dispatch('hotels/fetchHitoHotels', {
          is_random: 1,
          adults: 1,
          children: 0,
          business_type: businessType.value
        });
      }
    });
    watch([locale, businessType], function () {
      store.dispatch('hotels/fetchHitoHotels', {
        is_random: 1,
        adults: 1,
        children: 0,
        business_type: businessType.value
      });
    });
    watch(activeName, function () {
      var city = tags.value[activeName.value].city_id || 0;
      store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: city
      });
      store.dispatch('hotels/fetchHitoHotels', {
        city: city,
        is_random: 1,
        adults: 1,
        children: 0,
        business_type: businessType.value
      });
    });

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    return {
      t: t,
      tags: tags,
      activeName: activeName,
      hotelsData: hotelsData,
      handleGoHotels: handleGoHotels
    };
  }
});