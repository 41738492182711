import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8cd6f9f8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "slider-main"
};
var _hoisted_2 = {
  class: "previous action"
};
var _hoisted_3 = {
  class: "next action"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["slider-container", {
      desktop: _ctx.desktop
    }])
  }, [_createElementVNode("div", {
    class: "slider-tray",
    style: _normalizeStyle(_ctx.sliderStyle)
  }, [_renderSlot(_ctx.$slots, "container", {}, undefined, true)], 4)], 2), _withDirectives(_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "previous", {
    previous: _ctx.previous
  }, undefined, true)], 512), [[_vShow, _ctx.buttonState.shouldPreviousBeValid]]), _withDirectives(_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "next", {
    next: _ctx.next
  }, undefined, true)], 512), [[_vShow, _ctx.buttonState.shouldNextBeValid]])]);
}