import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2937cb8c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_search_section = _resolveComponent("search-section");

  var _component_banner = _resolveComponent("banner");

  var _component_hito_section = _resolveComponent("hito-section");

  var _component_hot_spot_section = _resolveComponent("hot-spot-section");

  var _component_info_section = _resolveComponent("info-section");

  var _component_footer_component = _resolveComponent("footer-component");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _createVNode(_component_search_section), _createElementVNode("div", _hoisted_1, [_createVNode(_component_banner), _createVNode(_component_hito_section), _createVNode(_component_hot_spot_section), _createVNode(_component_info_section)]), _createVNode(_component_footer_component)], 64);
}