import { defineComponent } from 'vue';
import MobileSearch from '@/components/mobile/MobileSearch.vue';
import HomePcSearch from '@/components/home/HomePcSearch.vue';
export default defineComponent({
  name: 'SearchSection',
  components: {
    MobileSearch: MobileSearch,
    HomePcSearch: HomePcSearch
  },
  inject: ['isMobile']
});