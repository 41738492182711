import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { defineComponent, computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import format from 'date-fns/format';
import SvgIcon from '@/components/share/SvgIcon.vue';
import KeywordSearch from '@/components/share/KeywordSearch.vue';
import PeoplePopover from '@/components/share/PeoplePopover.vue';
import { DATE_FORMAT } from '@/constants';
import { useSearchBar } from '@/hook/useSearchBar';
import { isPast, isToday, addMonths, startOfToday, isAfter } from 'date-fns';
export default defineComponent({
  name: 'HomePcSearch',
  components: {
    SvgIcon: SvgIcon,
    KeywordSearch: KeywordSearch,
    PeoplePopover: PeoplePopover // HotelTypePopover

  },
  setup: function setup() {
    var t = inject('t');
    var store = useStore();
    var dateText = computed(function () {
      return [format(search.value.startDate, DATE_FORMAT), format(search.value.endDate, DATE_FORMAT)];
    });
    var cityText = computed(function () {
      var _store$state$options$;

      return (_store$state$options$ = store.state.options.cities.find(function (city) {
        return city.city_id === search.value.city;
      })) === null || _store$state$options$ === void 0 ? void 0 : _store$state$options$.city_name;
    });

    var onDisabledDate = function onDisabledDate(date) {
      if (isToday(date)) {
        return false;
      } else {
        var lastDay = addMonths(startOfToday(), 11);
        return isPast(date) || isAfter(date, lastDay);
      }
    };

    var _useSearchBar = useSearchBar(),
        searchValue = _useSearchBar.searchValue,
        setShowFilter = _useSearchBar.setShowFilter,
        search = _useSearchBar.search,
        locationInput = _useSearchBar.locationInput,
        dateRange = _useSearchBar.dateRange,
        durationDays = _useSearchBar.durationDays,
        showFilter = _useSearchBar.showFilter,
        handleSearch = _useSearchBar.handleSearch,
        setLocation = _useSearchBar.setLocation,
        onClickOverlay = _useSearchBar.onClickOverlay,
        resetSearch = _useSearchBar.resetSearch,
        businessType = _useSearchBar.businessType,
        hotelType = _useSearchBar.hotelType;

    var showHint = ref(false);

    var onVisibleChanged = function onVisibleChanged(visible) {
      showHint.value = visible;
    };

    return {
      t: t,
      locationInput: locationInput,
      searchValue: searchValue,
      setLocation: setLocation,
      onClickOverlay: onClickOverlay,
      resetSearch: resetSearch,
      dateRange: dateRange,
      showFilter: showFilter,
      setShowFilter: setShowFilter,
      search: search,
      durationDays: durationDays,
      handleSearch: handleSearch,
      businessType: businessType,
      dateText: dateText,
      cityText: cityText,
      hotelType: hotelType,
      onVisibleChanged: onVisibleChanged,
      showHint: showHint,
      onDisabledDate: onDisabledDate
    };
  }
});