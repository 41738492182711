import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-67606675"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "hito"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "list"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "img-wrapper"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  class: "tag"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t('homepage.prevention_info')), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, function (data, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "card",
      key: index,
      onClick: function onClick($event) {
        return _ctx.openNewWindow(data.link);
      }
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
      class: "img",
      src: _ctx.isHighDensity ? "".concat(data.image).concat(_ctx.isMobile ? '_mobile' : '', "_2x.jpg") : "".concat(data.image).concat(_ctx.isMobile ? '_mobile' : '', ".jpg")
    }, null, 8, _hoisted_6)]), _createElementVNode("h5", _hoisted_7, _toDisplayString(data.name), 1)], 8, _hoisted_4);
  }), 128))])]);
}