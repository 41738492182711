import { computed, defineComponent, inject } from 'vue';
import { getInfoUrl } from '@/utils/infoUrl';
export default defineComponent({
  setup: function setup() {
    var isHighDensity = inject('isHighDensity');
    var isMobile = inject('isMobile');
    var t = inject('t');
    var info = computed(function () {
      return getInfoUrl(t);
    });

    var openNewWindow = function openNewWindow(url) {
      window.open(url);
    };

    return {
      t: t,
      info: info,
      isMobile: isMobile,
      isHighDensity: isHighDensity,
      openNewWindow: openNewWindow
    };
  }
});