import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "search-section"
};
var _hoisted_2 = {
  class: "selector date-range"
};
var _hoisted_3 = {
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_keyword_search = _resolveComponent("keyword-search");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_el_date_picker = _resolveComponent("el-date-picker");

  var _component_people_popover = _resolveComponent("people-popover");

  return _openBlock(), _createElementBlock("div", {
    id: "home-search",
    class: _normalizeClass({
      'search-bg': true,
      'travel-page': _ctx.businessType === 1
    })
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_keyword_search), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "icon",
    iconName: "search-check-in"
  }), _createTextVNode(" " + _toDisplayString(_ctx.dateText[0]), 1)]), _createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "icon",
    iconName: "search-check-out"
  }), _createTextVNode(" " + _toDisplayString(_ctx.dateText[1]), 1)]), _createVNode(_component_el_date_picker, {
    modelValue: _ctx.dateRange,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.dateRange = $event;
    }),
    name: ['my-range-start', 'my-range-end'],
    type: "daterange",
    "start-placeholder": "Start date",
    "end-placeholder": "End date",
    "range-separator": "-",
    "disabled-date": _ctx.onDisabledDate,
    onVisibleChange: _ctx.onVisibleChanged
  }, null, 8, ["modelValue", "disabled-date", "onVisibleChange"])]), _createVNode(_component_people_popover, {
    showPopover: _ctx.showFilter === 'people',
    placement: "bottom-start",
    onClickOverlay: function onClickOverlay() {
      return _ctx.setShowFilter('');
    }
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "selector people",
        onClick: _cache[1] || (_cache[1] = function () {
          return _ctx.setShowFilter('people');
        })
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "search-user"
      }), _createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(_ctx.t('homepage.n_adults', {
        n: _ctx.search.adults
      })), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.search.children > 0 ? "".concat(_ctx.t('homepage.n_children', {
        n: _ctx.search.children
      }), ",") : null) + " " + _toDisplayString(_ctx.t('homepage.n_room', {
        n: _ctx.search.room
      })), 1)])])];
    }),
    _: 1
  }, 8, ["showPopover", "onClickOverlay"])])]), _createElementVNode("button", {
    class: "search-btn",
    onClick: _cache[2] || (_cache[2] = //@ts-ignore
    function () {
      return _ctx.handleSearch && _ctx.handleSearch.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('homepage.search')), 1)], 2);
}